/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { StaticImage } from "gatsby-plugin-image"
import { SocialIcon } from "react-social-icons"

/**
 * Required Local Dependency Imports
 */

import Bottom from "./Bottom"

/**
 * @returns Left-Side Content for the Full-Width Footer (Non-Mobile)
 */
const FooterContentLeft = () => {
  const classes = [
    "col-span-2",
  ]

  const socialLinkClasses = [
    "flex",
    "space-x-6",
    "w-min",
  ]

  return (
    <div className={classes.join(" ")}>
      <a href="/">
        <StaticImage
          alt="KWAZI Logo"
          height={24}
          layout="fixed"
          loading="eager"
          placeholder="blurred"
          src="../../images/kwazi-logo-main-min.png" />
      </a>
      <div className="font-semibold mb-16 text-md tracking-wider uppercase">
        Ambitiously <span className="text-green-900">Different</span>
      </div>
      <div className={socialLinkClasses.join(" ")}>
        <SocialIcon href="https://www.facebook.com/kwazi.io" network="facebook" bgColor="black" style={{ height: 23, width: 23 }} />
        <SocialIcon href="https://github.com/kwaziio" network="github" bgColor="black" style={{ height: 23, width: 23 }} />
        <SocialIcon href="https://www.linkedin.com/company/kwaziio" network="linkedin" bgColor="black" style={{ height: 23, width: 23 }} />
        <SocialIcon href="https://twitter.com/kwazi_hq" network="x" bgColor="black" style={{ height: 23, width: 23 }} />
        <SocialIcon href="https://www.youtube.com/@kwaziio" network="youtube" bgColor="black" style={{ height: 23, width: 23 }} />
      </div>
    </div>
  )
}

/**
 * @returns Right-Side Content for the Full-Width Footer (Non-Mobile)
 */
const FooterContentRight = () => {
  const classes = [
    "col-span-3",
    "grid",
    "grid-cols-3",
  ]

  const headerClasses = [
    "font-semibold",
    "tracking-wider",
    "uppercase",
  ]

  const listClasses = [
    "pt-4",
    "space-y-3",
    "text-xs",
  ]

  return (
    <div className={classes.join(" ")}>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-blue-300">Services</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-blue-300"><a href="/services#infrastructure-automation">Infrastructure Automation</a></li>
          <li className="hover:text-blue-300"><a href="/services#site-reliability-engineering">Site Reliability Engineering (SRE)</a></li>
          <li className="hover:text-blue-300"><a href="/services#software-development">Software Development</a></li>
          <li className="hover:text-blue-300"><a href="/services#system-architecture-and-design">System Architecture and Design</a></li>
        </ul>
      </div>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-green-900">Solutions</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-green-900"><a href="/solutions#cloud-maintenance-and-migration">Cloud Maintenance and Migration</a></li>
          <li className="hover:text-green-900"><a href="/solutions#secret-lifecycle-management">Secret Lifecycle Management</a></li>
          <li className="hover:text-green-900"><a href="/solutions#software-pipeline-automation">Software Pipeline Automation</a></li>
          <li className="hover:text-green-900"><a href="/solutions#vulnerability-monitoring-and-assessment">Vulnerability Monitoring and Assessment</a></li>
        </ul>
      </div>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-blue-300">Company</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-blue-300"><a href="#infrastructure-automation">Contact Us</a></li>
          <li className="hover:text-blue-300"><a href="/industries">Industries</a></li>
          <li className="hover:text-blue-300"><a href="/products">Products</a></li>
        </ul>
      </div>
    </div>
  )
}

/**
 * @returns Content Section for the Full Footer (Non-Mobile)
 */
const Content = () => {
  const classes = [
    "grid",
    "grid-cols-5",
    "pb-8",
    "pt-24",
  ]

  return (
    <div className={classes.join(" ")}>
      <FooterContentLeft />
      <FooterContentRight />
    </div>
  )
}

/**
 * @returns Full-Sized Footer Component (Non-Mobile)
 */
const FullFooter = () => {
  const classes = [
    "hidden",
    "max-w-7xl",
    "mx-auto",
    "px-8",
    "lg:block",
  ]

  return (
    <footer className={classes.join(" ")} id="footer">
      <Content />
      <Bottom />
    </footer>
  )
}

/**
 * Default Export for this File
 */
export default FullFooter