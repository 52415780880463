/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Local Dependency Imports
 */

import FullNavigationMenu from "./FullNavigationMenu"
import MobileNavigationMenu from "./MobileNavigationMenu"

/**
 * @returns Top Navigation Bar Component for this Web Application
 */
const Navigation = () => {
  const classes = [
    "bg-white",
    "fixed",
    "left-0",
    "p-5",
    "px-8",
    "right-0",
    "shadow",
    "w-full",
    "top-0",
    "z-10",
  ]
  
  return (
    <nav aria-label="Global" className={classes.join(" ")}>
      <FullNavigationMenu />
      <MobileNavigationMenu />
    </nav>
  )
}

/**
 * Default Export for this Component Definition File
 */
export default Navigation
