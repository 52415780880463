/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Local Component Imports
 */

import Footer from "../Footer"
import Navigation from "../Navigation"

/**
 * @param {*} children Array of Child Nodes to be Displayed
 * @returns Root-Level Layout for this Web Application
 */
const Layout = ({children}) => (
  <div className="bg-white">
    <header>
      <Navigation />
    </header>
    <main className="overflow-hidden">
      <div className="mt-16">
        {children}
      </div>
    </main>
    <Footer />
  </div>
)

/**
 * Default Export for this Component Definition File
 */
export default Layout
