/**
 * Required Core Dependency Imports
 */

import { createSlice } from "@reduxjs/toolkit"

/**
 * Initial State for the Contact Slice
 */
const initialState = {
  firstName: "",
  firstNameIsValid: false,
  lastName: "",
  lastNameIsValid: false,
  email: "",
  emailIsValid: false,
  company: "",
  companyIsValid: false,
  phone: "",
  phoneIsValid: true,
  message: "",
  messageIsValid: false,
  response: "",
  sent: false,
}

/**
 * Contact Redux Slice Definition
 */
export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    reset: (state) => {
      state.firstName = initialState.firstName
      state.firstNameIsValid = initialState.firstNameIsValid
      state.lastName = initialState.lastName
      state.lastNameIsValid = initialState.lastNameIsValid
      state.email = initialState.email
      state.emailIsValid = initialState.emailIsValid
      state.company = initialState.company
      state.companyIsValid = initialState.companyIsValid
      state.phone = initialState.phone
      state.phoneIsValid = initialState.phoneIsValid
      state.message = initialState.message
      state.messageIsValid = initialState.messageIsValid
    },
    send: (state, action) => {
      state.response = action.payload.message
      state.sent = action.payload.value
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload.value
    },
    setFirstNameIsValid: (state, action) => {
      state.firstNameIsValid = action.payload.value
    },
    setLastName: (state, action) => {
      state.lastName = action.payload.value
    },
    setLastNameIsValid: (state, action) => {
      state.lastNameIsValid = action.payload.value
    },
    setEmail: (state, action) => {
      state.email = action.payload.value
    },
    setEmailIsValid: (state, action) => {
      state.emailIsValid = action.payload.value
    },
    setCompany: (state, action) => {
      state.company = action.payload.value
    },
    setCompanyIsValid: (state, action) => {
      state.companyIsValid = action.payload.value
    },
    setPhone: (state, action) => {
      state.phone = action.payload.value
    },
    setPhoneIsValid: (state, action) => {
      state.phoneIsValid = action.payload.value
    },
    setMessage: (state, action) => {
      state.message = action.payload.value
    },
    setMessageIsValid: (state, action) => {
      state.messageIsValid = action.payload.value
    },
  }
})

/**
 * Exports Contact Redux Slice Actions
 */
export const {
  send,
  setFirstName,
  setFirstNameIsValid,
  setLastName,
  setLastNameIsValid,
  setEmail,
  setEmailIsValid,
  setCompany,
  setCompanyIsValid,
  setPhone,
  setPhoneIsValid,
  setMessage,
  setMessageIsValid,
} = contactSlice.actions

/**
 * Default Export for this File
 */
export default contactSlice.reducer
