/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { navigate } from "gatsby"
import { useDispatch } from "react-redux"

/**
 * Required Local Dependency Imports
 */

import { close } from "../../state/navigationSlice"

/**
 * @returns Single Navigation Menu Item on Large Displays (Non-Mobile)
 */
const MobileNavigationMenuItem = ({ label, link }) => {
  const dispatch = useDispatch()

  const classes = [
    "font-semibold",
    "leading-6",
    "text-black",
    "text-md",
    "tracking-widest",
    "uppercase",
    "hover:text-green-900",
  ]

  const onClick = (e) => {
    e.preventDefault()
    navigate(link)
    dispatch(close())
  }

  return (
    <button className={classes.join(" ")} key={label} onClick={onClick}>
      {label}
    </button>
  )
}

/**
 * Default Export for this File
 */
export default MobileNavigationMenuItem
