/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * @returns Bottom Section of this Website's Footer Component
 */
const Bottom = () => {
  const classes = [
    "border-gray-300",
    "border-t",
    "mt-16",
    "py-8",
    "text-center",
  ]

  return (
    <div className={classes.join(" ")}>
      <p className="text-xs leading-5 text-gray-500">&copy; 2023 KWAZI, INC. All rights reserved.</p>
    </div>
  )
}

/**
 * Default Export for this File
 */
export default Bottom
