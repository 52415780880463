/**
* Required Third-Party Dependency Imports
*/

import AOS from "aos"

/**
 * Required Local Dependency Imports
 */

import wrapWithLayout from "./wrap-with-layout" 
import wrapWithProvider from "./wrap-with-provider" 

/**
* Initializes Global Features
*/
AOS.init()

/**
 * Wraps Every Page in a Layout Component
 */
export const wrapPageElement = wrapWithLayout

/**
 * Wraps Every Application Root Element w/ State Provider
 */
export const wrapRootElement = wrapWithProvider
