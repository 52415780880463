/**
 * Required Core Dependency Imports
 */

import { configureStore } from "@reduxjs/toolkit"

/**
 * Required Local Dependency Imports
 */

import contactReducer from "./contactSlice"
import navigationReducer from "./navigationSlice"

/**
 * Creates the Redux Store for this Application
 */
const createStore = () => configureStore({
  reducer: {
    contact: contactReducer,
    navigation: navigationReducer,
  }
})

/**
 * Default Export for this File
 */
export default createStore
