/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Local Dependency Imports
 */

import Layout from "./src/components/Layout"

/**
* Required Required Styling Imports
*/

import "aos/dist/aos.css"
import "./src/styles/global.css"

/**
 * Wraps the Provided Element (Page) with a Custom Layout Component
 */
const wrapWithLayout = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

/**
 * Default Export for this File
 */
export default wrapWithLayout
