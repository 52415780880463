/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Local Dependency Imports
 */

import FullNavigationMenuContactButton from "./FullNavigationMenuContactButton"
import FullNavigationMenuItem from "./FullNavigationMenuItem"
import LogoButton from "./LogoButton"

/**
 * @returns Left Section of the Full Navigation Menu
 */
const FullNavigationLeft = () => {
  const classes = [
    "flex",
    "flex-1",
    "justify-start",
  ]

  return (
    <div className={classes.join(" ")}>
      <LogoButton />
    </div>
  )
}

/**
 * @returns Middle Section of the Full Navigation Menu
 */
const FullNavigationMiddle = () => {
  const classes = [
    "flex",
    "flex-1",
    "gap-x-12",
    "justify-center",
  ]

  return (
    <div className={classes.join(" ")}>
      <FullNavigationMenuItem label="Services" link="/services" />
      <FullNavigationMenuItem label="Solutions" link="/solutions" />
      <FullNavigationMenuItem label="Industries" link="/industries" />
      <FullNavigationMenuItem label="Products" link="/products" />
    </div>
  )
}

/**
 * @returns Right Section of the Full Navigation Menu
 */
const FullNavigationRight = () => {
  const classes = [
    "flex",
    "flex-1",
    "justify-end",
  ]

  return (
    <div className={classes.join(" ")}>
      <FullNavigationMenuContactButton />
    </div>
  )
}

/**
 * @returns Global Navigation Bar Visible to Large Displays (Non-Mobile)
 */
const FullNavigationMenu = () => {
  const classes = [
    "h-6",
    "hidden",
    "justify-between",
    "mx-auto",
    "lg:flex",
  ]

  return (
    <div className={classes.join(" ")}>
      <FullNavigationLeft />
      <FullNavigationMiddle />
      <FullNavigationRight />
    </div>
  )
}

/**
 * Default Export for this File
 */
export default FullNavigationMenu
