/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useDispatch } from "react-redux"

/**
 * Required Local Dependency Imports
 */

import { close } from "../../state/navigationSlice"

/**
 * @returns Logo Button for Navigating Home via the Navigation Menu
 */
const LogoButton = () => {
  const dispatch = useDispatch()

  const classes = [
    "flex",
    "h-full",
    "items-center",
  ]

  const onClick = (e) => {
    e.preventDefault()
    navigate("/")
    dispatch(close())
  }

  return (
    <div className={classes.join(" ")}>
      <button onClick={onClick}>
        <StaticImage
          alt="KWAZI Logo"
          height={16}
          layout="fixed"
          loading="eager"
          placeholder="blurred"
          src="../../images/kwazi-logo-main-min.png" />
      </button>
    </div>
  )
}

/**
 * Default Export for this File
 */
export default LogoButton
