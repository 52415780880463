/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { Provider } from "react-redux"

/**
 * Required Local Dependency Imports
 */

import createStore from "./src/state/createStore"

/**
 * Wraps the Provided Element with a Redux State Provider
 */
const wrapWithProvider = ({ element }) => {
  const store = createStore()
  
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}

/**
 * Default Export for this File
 */
export default wrapWithProvider
