/**
 * Required Core Dependency Imports
 */

import { createSlice } from "@reduxjs/toolkit"

/**
 * Initial State for the Navigation Slice
 */
const initialState = {
  isOpen: false,
}

/**
 * Navigation Redux Slice Definition
 */
export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    open: (state) => {
      state.isOpen = true
    },
    close: (state) => {
      state.isOpen = false
    },
  }
})

/**
 * Exports Navigation Redux Slice Actions
 */
export const { close, open } = navigationSlice.actions

/**
 * Default Export for this File
 */
export default navigationSlice.reducer
