/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { SocialIcon } from "react-social-icons"

/**
 * Required Local Dependency Imports
 */

import Bottom from "./Bottom"

/**
 * @returns Leading Header Section for Mobile View Footer
 */
const FooterContentHeader = () => {
  const classes = [
    "col-span-1",
    "mb-8",
  ]

  const socialLinkClasses = [
    "flex",
    "mx-auto",
    "space-x-6",
    "w-min",
  ]

  return (
    <div className={classes.join(" ")}>
      <div className={socialLinkClasses.join(" ")}>
        <SocialIcon href="https://www.facebook.com/kwazi.io" network="facebook" bgColor="black" style={{ height: 30, width: 30 }} />
        <SocialIcon href="https://github.com/kwaziio" network="github" bgColor="black" style={{ height: 30, width: 30 }} />
        <SocialIcon href="https://www.linkedin.com/company/kwaziio" network="linkedin" bgColor="black" style={{ height: 30, width: 30 }} />
        <SocialIcon href="https://twitter.com/kwazi_hq" network="x" bgColor="black" style={{ height: 30, width: 30 }} />
        <SocialIcon href="https://www.youtube.com/@kwaziio" network="youtube" bgColor="black" style={{ height: 30, width: 30 }} />
      </div>
    </div>
  )
}

/**
 * @returns Site Map Section for Mobile View Footer
 */
const FooterContentMap = () => {
  const classes = [
    "border-gray-300",
    "border-t",
    "col-span-1",
    "grid",
    "grid-cols-1",
    "pt-16",
    "space-y-6",
    "text-center",
  ]

  const headerClasses = [
    "font-semibold",
    "tracking-wider",
    "uppercase",
  ]

  const listClasses = [
    "pt-4",
    "space-y-3",
    "text-sm",
  ]

  return (
    <div className={classes.join(" ")}>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-blue-300">Services</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-blue-300"><a href="/services#infrastructure-automation">Infrastructure Automation</a></li>
          <li className="hover:text-blue-300"><a href="/services#site-reliability-engineering">Site Reliability Engineering (SRE)</a></li>
          <li className="hover:text-blue-300"><a href="/services#software-development">Software Development</a></li>
          <li className="hover:text-blue-300"><a href="/services#system-architecture-and-design">System Architecture and Design</a></li>
        </ul>
      </div>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-green-900">Solutions</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-green-900"><a href="/solutions#cloud-maintenance-and-migration">Cloud Maintenance and Migration</a></li>
          <li className="hover:text-green-900"><a href="/solutions#secret-lifecycle-management">Secret Lifecycle Management</a></li>
          <li className="hover:text-green-900"><a href="/solutions#software-pipeline-automation">Software Pipeline Automation</a></li>
          <li className="hover:text-green-900"><a href="/solutions#vulnerability-monitoring-and-assessment">Vulnerability Monitoring and Assessment</a></li>
        </ul>
      </div>
      <div className="col-span-1">
        <h3 className={headerClasses.join(" ")}>Our <span className="text-blue-300">Company</span></h3>
        <ul className={listClasses.join(" ")}>
          <li className="hover:text-blue-300"><a href="#infrastructure-automation">Contact Us</a></li>
          <li className="hover:text-blue-300"><a href="/industries">Industries</a></li>
          <li className="hover:text-blue-300"><a href="/products">Products</a></li>
        </ul>
      </div>
    </div>
  )
}

/**
 * @returns Content Section for the Mobile Footer
 */
const Content = () => {
  const classes = [
    "grid",
    "grid-cols-1",
    "pt-8",
  ]

  return (
    <div className={classes.join(" ")}>
      <FooterContentHeader />
      <FooterContentMap />
    </div>
  )
}

/**
 * @returns Footer View Specifically for Mobile Devices
 */
const MobileFooter = () => {
  const classes = [
    "block",
    "mx-auto",
    "px-8",
    "w-full",
    "lg:hidden",
  ]

  return (
    <footer className={classes.join(" ")} id="footer">
      <Content />
      <Bottom />
    </footer>
  )
}

/**
 * Default Export for this File
 */
export default MobileFooter