/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { navigate } from "gatsby"
import { EnvelopeIcon } from "@heroicons/react/24/solid"
import { useDispatch } from "react-redux"

/**
 * Required Local Dependency Imports
 */

import { close } from "../../state/navigationSlice"

/**
 * @returns Contact Button for Accessing the Contact via the Navigation Bar
 */
const ContactButton = () => {
  const dispatch = useDispatch()

  const classes = [
    "bg-black",
    "font-semibold",
    "leading-6",
    "py-3",
    "rounded-md",
    "text-white",
    "text-sm",
    "tracking-widest",
    "uppercase",
    "hover:text-green-500",
  ]

  const iconClasses = [
    "inline",
    "pr-3",
  ]

  const onClick = (e) => {
    e.preventDefault()
    navigate("/contact")
    dispatch(close())
  }

  return (
    <button className={classes.join(" ")} onClick={onClick}>
      <EnvelopeIcon className={iconClasses.join(" ")} height={18} /><span>Contact Us</span>
    </button>
  )
}

/**
 * Default Export for this File
 */
export default ContactButton
