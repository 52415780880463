/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Local Dependency Imports
 */

import FullFooter from "./FullFooter"
import MobileFooter from "./MobileFooter"

/**
 * @returns Shared Footer Component for this Website
 */
const Footer = () => {
  const classes = [
    "bg-white",
    "w-full",
  ]

  return (
    <footer className={classes.join(" ")} id="footer">
      <FullFooter />
      <MobileFooter />
    </footer>
  )
}

/**
 * Default Export for this File
 */
export default Footer