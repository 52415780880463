/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"
import { useSelector, useDispatch } from "react-redux"

/**
 * Required Local Dependency Imports
 */

import MobileNavigationMenuContactButton from "./MobileNavigationMenuContactButton"
import MobileNavigationMenuItem from "./MobileNavigationMenuItem"
import LogoButton from "./LogoButton"
import { close, open } from "../../state/navigationSlice"

/**
 * @returns Left Section of the Mobile Navigation Menu
 */
const MobileNavigationLeft = () => {
  const classes = [
    "flex"
  ]

  return (
    <div className={classes.join(" ")}>
      <LogoButton />
    </div>
  )
}

/**
 * @returns Right Section of the Mobile Navigation Menu
 */
const MobileNavigationRight = () => {
  const isOpen = useSelector(state => state.navigation.isOpen)
  const dispatch = useDispatch()

  const classes = [
    "flex"
  ]

  return (
    <div className={classes.join(" ")}>
      {isOpen ? 
        <XMarkIcon onClick={() => dispatch(close())} /> : 
        <Bars3Icon onClick={() => dispatch(open())} />
      }
    </div>
  )
}

/**
 * @returns Bottom Section of the Mobile Navigation Menu
 */
const MobileNavigationBottom = () => {
  const isOpen = useSelector(state => state.navigation.isOpen)

  const classes = [
    "grid",
    "grid-1",
    "gap-y-12",
    "mt-5",
    "py-8",
  ]

  return (
    !isOpen ? <div></div> : 
    <div className={classes.join(" ")}>
      <MobileNavigationMenuItem label="Services" link="/services" />
      <MobileNavigationMenuItem label="Solutions" link="/solutions" />
      <MobileNavigationMenuItem label="Industries" link="/industries" />
      <MobileNavigationMenuItem label="Products" link="/products" />
      <MobileNavigationMenuContactButton />
    </div>
  )
}

/**
 * @returns Global Navigation Bar Visible to Mobile Displays
 */
const MobileNavigationMenu = () => {
  const classes = [
    "flex",
    "h-6",
    "justify-between",
    "mx-auto",
    "lg:hidden",
  ]

  return (
    <div className="lg:hidden">
      <div className={classes.join(" ")}>
        <MobileNavigationLeft />
        <MobileNavigationRight />
      </div>
      <MobileNavigationBottom />
    </div>
  )
}

/**
 * Default Export for this File
 */
export default MobileNavigationMenu
