/**
 * Required Core Dependency Imports
 */

import React from "react"

/**
 * Required Third-Party Dependency Imports
 */

import { navigate } from "gatsby"

/**
 * @returns Single Navigation Menu Item on Large Displays (Non-Mobile)
 */
const FullNavigationMenuItem = ({ label, link }) => {
  const classes = [
    "font-semibold",
    "leading-6",
    "text-black",
    "text-sm",
    "tracking-widest",
    "uppercase",
    "hover:text-green-900",
  ]

  return (
    <button className={classes.join(" ")} key={label} onClick={() => navigate(link)}>
      {label}
    </button>
  )
}

/**
 * Default Export for this File
 */
export default FullNavigationMenuItem
